<template>
  <div class="main-login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">

      <div class="title-container">
        <h3 class="title">滇约出行 快乐随行</h3>
      </div>

      <div class="form-container">
        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入账号"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              name="password"
              tabindex="2"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
        </el-tooltip>
<!--        <Verify ref="verify" :loginForm="loginForm" />-->
        <el-button class="login-btn" v-show="!sliderShow" :loading="loading" :disabled="loading" type="primary" style="width:100%;" @click.native.prevent="handleLogin">登录</el-button>
      </div>
    </el-form>

    <el-dialog
      title="登录验证"
      :visible.sync="sliderShow"
      width="325px"
      center>
      <SliderVerify ref="SliderVerify" :show="sliderShow" @sliderLogin="sliderLogin" v-loading="loading"     element-loading-text="登录中..."
                    element-loading-spinner="el-icon-loading"/>
    </el-dialog>
  </div>
</template>

<script>
// import Verify from './components/Verify'
import Watermark, { delWatermark } from '@/utils/watermark/index.js'
import SliderVerify from './components/SliderVerify'
export default {
  name: 'Login',
  components: { SliderVerify },
  data () {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入账号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码至少6个字符'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: '',
        captchaValue: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        captchaValue: [{ required: true, trigger: 'blur', len: 4, message: '请输入合法的验证码' }]
      },
      sliderShow: false,
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
    if (process.env.VUE_APP_ENV === 'PROD') {
      delWatermark()
    }
  },
  beforeDestroy() {
    if (process.env.VUE_APP_ENV === 'PROD') {
      Watermark()
    }
  },
  methods: {
    checkCapslock (e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd () {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    /**
     * 滑块登录验证
     * @param data
     */
    sliderLogin(data) {
      this.loginForm.captchaValue = data.track
      this.loginForm.captchaKey = data.id || ''
      this.loading = true
      const that = this
      const form = this.loginForm
      form.clientCode = 'dy-platform-sys'
      this.$store.dispatch('user/login', form)
        .then((e) => {
          const toJump = this.$route.query.redirect
          if (toJump) {
            this.$router.push(toJump)
          } else {
            this.$router.push({ path: '/' })
          }
          this.loading = false
          this.sliderShow = false
        })
        .catch((e) => {
          that.$refs.SliderVerify.$_refresh()
          that.$refs.SliderVerify.$_remove_event_listener()
          that.loading = false
          that.sliderShow = false
        })
    },
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.sliderShow = true
        }
      })
    },
    getOtherQuery (query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss">

/* reset element-ui css */
.main-login-container {

  .el-form-item {
    position: relative;
    margin-bottom: 32px;

    input {
      height: 56px;
      padding-left: 50px;
      font-size: 20px;
      font-weight: 500;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$green: #07b885;
$dark_gray:#889aa4;
$light_gray:#eee;

.main-login-container {
  min-height: 100%;
  width: 100%;
  padding: 100px 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: url(../../assets/images/login-bg1.png) no-repeat;
  background-size: cover;

  .login-form {
    width: 580px;
    max-width: 100%;
    overflow: hidden;
  }

  .form-container {
    padding: 100px 68px 80px;
    background-color: rgba(255,255,255,0.1);
    border-radius: 20px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 10px 6px 20px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 60px;
      color: $green;
      margin: 0px auto 68px;
      text-align: center;
      font-weight: bold;
      display: none;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
    transform: translateY(-50%);
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  .login-btn {
    height: 56px;
    margin-top: 40px;
    font-size: 20px;
    font-weight: 500;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
