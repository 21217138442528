import request from '@/utils/request'

export function login (data = {}) {
  return request({
    url: '/web/user/login',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 获取用户信息
 * @param {*} params
 * @returns
 */
export function getInfo (params) {
  return request({
    url: '/web/user/get',
    method: 'get',
    params
  })
}

/**
 * 获取子应用菜单集合
 * @returns
 */
export function getRootMenu () {
  return request({
    url: '/resource/get/root',
    method: 'get'
  })
}

/**
 * 根据子应用id获取子菜单
 * @param {*} params
 * @returns
 */
export function getMenuByPid (params) {
  return request({
    url: '/resource/get/pid',
    method: 'get',
    params
  })
}

export function logout () {
  return request({
    url: '/web/user/logout',
    method: 'post'
  })
}

/**
 * 获取验证码图片
 * @returns
 */
export function createCaptcha() {
  return request({
    url: '/createCaptcha',
    method: 'get'
  })
}
